  /****
  Make the default mapbox controls larger, smaller box shadow and less rounded.
  ****/

  .mapboxgl-ctrl-group>button {
    width: 36px;
    height: 36px;
  }
  .mapboxgl-ctrl-group:not(:empty) {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  /* .mapboxgl-ctrl-top-right {
    right: -100px;
  }
  .mapboxgl-ctrl-top-left {
    left: -100px
  }
  .mapboxgl-ctrl-top-right,
  .mapboxgl-ctrl-top-left {
    transition: 0.3s ease-in-out;
    height: 100vh;
    min-width: 60px;
  }

  .mapboxgl-ctrl-top-right.visible {
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 60px;
  } */

  /****
  ****/

  .mgl-layerControl {
    font-size: 16px;
    scroll-behavior: smooth;
  }

  /****
  * COLLAPSED
  ****/

  .mgl-layerControl.collapsed {
    width: 36px;
    height: 36px;
    overflow: hidden;
    background-image: url("https://icongr.am/material/layers.svg");
    background-size: cover;
    background-position: center;
  }

  .mgl-layerControl.collapsed > div {
    display: none;
  }

  .mgl-layerControl:not(.collapsed) > img {
    display: none;
  }

  .mgl-layerControl:not(.collapsed) {
    height: auto;
    width:  calc(100% + 20px);
    max-width: 100%;
    overflow-x: hidden;
    display: block;
    overflow-y: auto;
  }

  /****
  ****/
  .mgl-layerControlLegend {
    margin-right: 4px;
    padding-right: 4px;
  }
  .mgl-layerControlGroupHeading {
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
    display: inline-flex;
  }

  .mgl-layerControl button {
    width: 30px;
    height: 26px;
    border: none;
    opacity: 0.6;
    float: left;
    background-size: 20px;
    background-position: center center;
    background-color: transparent;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='mdi-minus' width='32' height='32' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M19,13H5V11H19V13Z'/%3E%3C/svg%3E");  }

  .mgl-layerControl button.collapsed {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='mdi-plus' width='32' height='32' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z'/%3E%3C/svg%3E");
   }

  .mgl-layerControl icon {
    margin-right: 6px
  }

  .mgl-layerControlLegend:not(label) {
    margin-left: 20px;
    max-width: calc(100% - 32px);
    line-height: 20px;
  }

  .mgl-layerControlDirectory {
    transition: height 0.4s ease-in-out;
    height: auto;
    overflow: hidden;
    border-bottom: solid thin #e0e0e0;
  }

  .mgl-layerControlDirectory > .directory {
    background: #f8f9fa;
    padding: 2px;
    cursor: pointer;
  }

  .mgl-layerControl .checkbox,
  .mgl-layerControl button,
  .mgl-layerControl .layer.side-toggle,
  .mgl-layerControl label {
    cursor: pointer;
  }

  .mgl-layerControl .checkbox {
    font-size: 0.9em;
    margin: 4px;
  }

  /****
INPUT SLIDERS
****/

input.slide-toggle {
  margin: 0 6px 0 0;
  cursor: pointer;
  border: solid thin #ccc;
  position: relative;
  -webkit-appearance: none;
  outline: none;
  width: 24px;
  height: 10px;
  background-color: #eee;
  border-radius: 2px;
  transition: 0.2s;
  background-clip: padding-box;
}

input.slide-toggle:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  background: #fff;
  width: 8px;
  height: 8px;
  border-radius: 1px;
  box-shadow: 0 0 1px 1px #ccc;
  transition: 0.2s;
}

input.slide-toggle:checked:after {
  left: 14px;
  box-shadow: 0 0 1px 1px #0d84b3;
}
input.slide-toggle:focus {
  border-color: #0d84b3; 
  box-shadow: 0 0 0 0.1rem rgba(13,132,179,.2);
}

input.slide-toggle:checked {
  background: #0d84b3;
  border-color: #0d84b3;
}
/**
SPECTRE MODAL
**/
.modal {
  align-items: center;
  bottom: 0;
  display: none;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  opacity: 0;
  overflow: hidden;
  padding: .4rem;
  position: fixed;
  right: 0;
  top: 0;
}

.modal:target,
.modal.active {
  display: flex;
  display: -ms-flexbox;
  opacity: 1;
  z-index: 400;
}

.modal:target .modal-overlay,
.modal.active .modal-overlay {
  background: rgba(248, 249, 250, .75);
  bottom: 0;
  cursor: default;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal:target .modal-container,
.modal.active .modal-container {
  animation: slide-down .2s ease 1;
  z-index: 1;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 .4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  background: #fff;
  border-radius: .1rem;
  box-shadow: 0 .2rem .5rem rgba(69, 77, 93, .3);
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: 75vh;
  max-width: 640px;
  padding: 0 .8rem;
  width: 100%;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #454d5d;
  padding: .8rem;
}

.modal-container .modal-body {
  overflow-y: auto;
  padding: .8rem;
  position: relative;
}

.modal-container .modal-footer {
  padding: .8rem;
  text-align: right;
}