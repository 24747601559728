.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder *, .mapboxgl-ctrl-geocoder :after, .mapboxgl-ctrl-geocoder :before {
  box-sizing: border-box;
}

.mapboxgl-ctrl-geocoder {
  width: 100%;
  min-width: 240px;
  z-index: 1;
  background-color: #fff;
  border-radius: 4px;
  font-family: Open Sans, Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  transition: width .25s, min-width .25s;
  position: relative;
}

.mapboxgl-ctrl-geocoder--input {
  font: inherit;
  width: 100%;
  height: 50px;
  color: #404040;
  color: #000000bf;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #0000;
  border: 0;
  margin: 0;
  padding: 6px 45px;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--input::-ms-clear {
  display: none;
}

.mapboxgl-ctrl-geocoder--input:focus {
  color: #404040;
  color: #000000bf;
  box-shadow: none;
  outline: thin dotted;
}

.mapboxgl-ctrl-geocoder .mapboxgl-ctrl-geocoder--pin-right > * {
  z-index: 2;
  display: none;
  position: absolute;
  top: 7px;
  right: 8px;
}

.mapboxgl-ctrl-geocoder, .mapboxgl-ctrl-geocoder .suggestions {
  box-shadow: 0 0 10px 2px #0000001a;
}

.mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
  width: 50px;
  min-width: 50px;
  transition: width .25s, min-width .25s;
}

.mapboxgl-ctrl-geocoder .suggestions {
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  font-size: 15px;
  list-style: none;
  position: absolute;
  top: calc(100% + 6px);
  left: 0;
  overflow: hidden;
}

.mapboxgl-ctrl-bottom-left .suggestions, .mapboxgl-ctrl-bottom-right .suggestions {
  top: auto;
  bottom: 100%;
}

.mapboxgl-ctrl-geocoder .suggestions > li > a {
  cursor: default;
  color: #404040;
  padding: 6px 12px;
  display: block;
}

.mapboxgl-ctrl-geocoder .suggestions > .active > a, .mapboxgl-ctrl-geocoder .suggestions > li > a:hover {
  color: #404040;
  cursor: pointer;
  background-color: #f3f3f3;
  text-decoration: none;
}

.mapboxgl-ctrl-geocoder--suggestion-title {
  font-weight: bold;
}

.mapboxgl-ctrl-geocoder--suggestion-title, .mapboxgl-ctrl-geocoder--suggestion-address {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.mapboxgl-ctrl-geocoder--icon {
  vertical-align: middle;
  speak: none;
  fill: #757575;
  display: inline-block;
  top: 15px;
}

.mapboxgl-ctrl-geocoder--icon-search {
  width: 23px;
  height: 23px;
  position: absolute;
  top: 13px;
  left: 12px;
}

.mapboxgl-ctrl-geocoder--button {
  cursor: pointer;
  background: #fff;
  border: none;
  margin: 0;
  padding: 0;
  line-height: 1;
}

.mapboxgl-ctrl-geocoder--icon-close {
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 3px;
}

.mapboxgl-ctrl-geocoder--button:hover .mapboxgl-ctrl-geocoder--icon-close {
  fill: #909090;
}

.mapboxgl-ctrl-geocoder--icon-loading {
  width: 26px;
  height: 26px;
  margin-top: 5px;
  margin-right: 0;
  animation: .8s cubic-bezier(.45, .05, .55, .95) infinite rotate;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 640px) {
  .mapboxgl-ctrl-geocoder.mapboxgl-ctrl-geocoder--collapsed {
    width: 36px;
    min-width: 36px;
  }

  .mapboxgl-ctrl-geocoder {
    width: 33.3333%;
    max-width: 360px;
    font-size: 15px;
    line-height: 20px;
  }

  .mapboxgl-ctrl-geocoder .suggestions {
    font-size: 13px;
  }

  .mapboxgl-ctrl-geocoder--icon {
    top: 8px;
  }

  .mapboxgl-ctrl-geocoder--icon-close {
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-right: 0;
  }

  .mapboxgl-ctrl-geocoder--icon-search {
    width: 20px;
    height: 20px;
    left: 7px;
  }

  .mapboxgl-ctrl-geocoder--input {
    height: 36px;
    padding: 6px 35px;
  }

  .mapboxgl-ctrl-geocoder--icon-loading {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-right: -5px;
  }

  .mapbox-gl-geocoder--error {
    color: #909090;
    text-align: center;
    padding: 6px 12px;
    font-size: 16px;
  }
}

.mapboxgl-ctrl-group > button {
  width: 36px;
  height: 36px;
}

.mapboxgl-ctrl-group:not(:empty) {
  border-radius: 2px;
  box-shadow: 0 0 0 1px #0000001a;
}

.mgl-layerControl {
  scroll-behavior: smooth;
  font-size: 16px;
}

.mgl-layerControl.collapsed {
  width: 36px;
  height: 36px;
  background-image: url("https://icongr.am/material/layers.svg");
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.mgl-layerControl.collapsed > div, .mgl-layerControl:not(.collapsed) > img {
  display: none;
}

.mgl-layerControl:not(.collapsed) {
  height: auto;
  width: calc(100% + 20px);
  max-width: 100%;
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
}

.mgl-layerControlLegend {
  margin-right: 4px;
  padding-right: 4px;
}

.mgl-layerControlGroupHeading {
  cursor: pointer;
  font-size: 1em;
  font-weight: 600;
  display: inline-flex;
}

.mgl-layerControl button {
  width: 30px;
  height: 26px;
  opacity: .6;
  float: left;
  background-color: #0000;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='mdi-minus' width='32' height='32' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M19,13H5V11H19V13Z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  border: none;
}

.mgl-layerControl button.collapsed {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='mdi-plus' width='32' height='32' viewBox='0 0 24 24' fill='currentColor'%3E%3Cpath d='M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z'/%3E%3C/svg%3E");
}

.mgl-layerControl icon {
  margin-right: 6px;
}

.mgl-layerControlLegend:not(label) {
  max-width: calc(100% - 32px);
  margin-left: 20px;
  line-height: 20px;
}

.mgl-layerControlDirectory {
  height: auto;
  border-bottom: thin solid #e0e0e0;
  transition: height .4s ease-in-out;
  overflow: hidden;
}

.mgl-layerControlDirectory > .directory {
  cursor: pointer;
  background: #f8f9fa;
  padding: 2px;
}

.mgl-layerControl .checkbox, .mgl-layerControl button, .mgl-layerControl .layer.side-toggle, .mgl-layerControl label {
  cursor: pointer;
}

.mgl-layerControl .checkbox {
  margin: 4px;
  font-size: .9em;
}

input.slide-toggle {
  cursor: pointer;
  -webkit-appearance: none;
  width: 24px;
  height: 10px;
  background-color: #eee;
  background-clip: padding-box;
  border: thin solid #ccc;
  border-radius: 2px;
  outline: none;
  margin: 0 6px 0 0;
  transition: all .2s;
  position: relative;
}

input.slide-toggle:after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 1px;
  transition: all .2s;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 1px 1px #ccc;
}

input.slide-toggle:checked:after {
  left: 14px;
  box-shadow: 0 0 1px 1px #0d84b3;
}

input.slide-toggle:focus {
  border-color: #0d84b3;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

input.slide-toggle:checked {
  background: #0d84b3;
  border-color: #0d84b3;
}

.modal {
  opacity: 0;
  -ms-flex-align: center;
  justify-content: center;
  align-items: center;
  padding: .4rem;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal:target, .modal.active {
  opacity: 1;
  z-index: 400;
  display: -ms-flexbox;
}

.modal:target .modal-overlay, .modal.active .modal-overlay {
  cursor: default;
  background: #f8f9fabf;
  display: block;
  position: absolute;
  inset: 0;
}

.modal:target .modal-container, .modal.active .modal-container {
  z-index: 1;
  animation: .2s slide-down;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 .4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  max-height: 75vh;
  max-width: 640px;
  width: 100%;
  background: #fff;
  border-radius: .1rem;
  flex-direction: column;
  padding: 0 .8rem;
  display: -ms-flexbox;
  box-shadow: 0 .2rem .5rem #454d5d4d;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #454d5d;
  padding: .8rem;
}

.modal-container .modal-body {
  padding: .8rem;
  position: relative;
  overflow-y: auto;
}

.modal-container .modal-footer {
  text-align: right;
  padding: .8rem;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, footer, header, nav, section {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

figcaption, figure, main {
  display: block;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

a {
  -webkit-text-decoration-skip: objects;
  background-color: #0000;
}

a:active, a:hover {
  outline-width: 0;
}

address {
  font-style: normal;
}

b, strong {
  font-weight: inherit;
  font-weight: bolder;
}

code, kbd, pre, samp {
  font-family: SF Mono, Segoe UI Mono, Roboto Mono, Menlo, Courier, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

small {
  font-size: 80%;
  font-weight: 400;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

audio, video {
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
  display: inline-block;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details, menu {
  display: block;
}

summary {
  outline: none;
  display: list-item;
}

canvas {
  display: inline-block;
}

template, [hidden] {
  display: none;
}

*, :before, :after {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  font-size: 20px;
  line-height: 1.5;
}

body {
  color: #2d2d2d;
  text-rendering: optimizelegibility;
  background: #fff;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif;
  font-size: .8rem;
  overflow-x: hidden;
}

a {
  color: #0d84b3;
  outline: none;
  text-decoration: none;
}

a:focus {
  box-shadow: 0 0 0 .1rem #0d84b333;
}

a:focus, a:hover, a:active, a.active {
  color: #0b729b;
  text-decoration: underline;
}

a:visited {
  color: #10a7e3;
}

h1, h2, h3, h4, h5, h6 {
  color: inherit;
  margin-top: 0;
  margin-bottom: .5em;
  font-weight: 500;
  line-height: 1.2;
}

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 500;
}

h1, .h1 {
  font-size: 2rem;
}

h2, .h2 {
  font-size: 1.6rem;
}

h3, .h3 {
  font-size: 1.4rem;
}

h4, .h4 {
  font-size: 1.2rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: .8rem;
}

p {
  margin: 0 0 1rem;
}

a, ins, u {
  text-decoration-skip: ink edges;
}

abbr[title] {
  cursor: help;
  border-bottom: .05rem dotted;
  text-decoration: none;
}

kbd {
  color: #fff;
  background: #202020;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1.25;
}

mark {
  color: #2d2d2d;
  background: #ffe9b3;
  border-bottom: .05rem solid #ffd367;
  border-radius: .1rem;
  padding: .05rem .1rem 0;
}

blockquote {
  border-left: .1rem solid #b9b9b9;
  margin-left: 0;
  padding: .4rem .8rem;
}

blockquote p:last-child {
  margin-bottom: 0;
}

ul, ol {
  margin: .8rem 0 .8rem .8rem;
  padding: 0;
}

ul ul, ul ol, ol ul, ol ol {
  margin: .8rem 0 .8rem .8rem;
}

ul li, ol li {
  margin-top: .4rem;
}

ul {
  list-style: inside;
}

ul ul {
  list-style-type: circle;
}

ol {
  list-style: decimal inside;
}

ol ol {
  list-style-type: lower-alpha;
}

dl dt {
  font-weight: bold;
}

dl dd {
  margin: .4rem 0 .8rem;
}

html:lang(zh), html:lang(zh-Hans), .lang-zh, .lang-zh-hans {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, sans-serif;
}

html:lang(zh-Hant), .lang-zh-hant {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, PingFang TC, Hiragino Sans CNS, Microsoft JhengHei, Helvetica Neue, sans-serif;
}

html:lang(ja), .lang-ja {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Hiragino Sans, Hiragino Kaku Gothic Pro, Yu Gothic, YuGothic, Meiryo, Helvetica Neue, sans-serif;
}

html:lang(ko), .lang-ko {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Malgun Gothic, Helvetica Neue, sans-serif;
}

:lang(zh) ins, :lang(zh) u, :lang(ja) ins, :lang(ja) u, .lang-cjk ins, .lang-cjk u {
  border-bottom: .05rem solid;
  text-decoration: none;
}

:lang(zh) del + del, :lang(zh) del + s, :lang(zh) ins + ins, :lang(zh) ins + u, :lang(zh) s + del, :lang(zh) s + s, :lang(zh) u + ins, :lang(zh) u + u, :lang(ja) del + del, :lang(ja) del + s, :lang(ja) ins + ins, :lang(ja) ins + u, :lang(ja) s + del, :lang(ja) s + s, :lang(ja) u + ins, :lang(ja) u + u, .lang-cjk del + del, .lang-cjk del + s, .lang-cjk ins + ins, .lang-cjk ins + u, .lang-cjk s + del, .lang-cjk s + s, .lang-cjk u + ins, .lang-cjk u + u {
  margin-left: .125em;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  text-align: left;
}

.table.table-striped tbody tr:nth-of-type(odd) {
  background: #f1f1f1;
}

.table tbody tr.active, .table.table-striped tbody tr.active, .table.table-hover tbody tr:hover {
  background: #e9e9e9;
}

.table.table-scroll {
  white-space: nowrap;
  padding-bottom: .75rem;
  display: block;
  overflow-x: auto;
}

.table td, .table th {
  border-bottom: .05rem solid #b9b9b9;
  padding: .6rem .4rem;
}

.table th {
  border-bottom-width: .1rem;
}

.btn {
  -webkit-appearance: none;
  appearance: none;
  color: #0d84b3;
  cursor: pointer;
  height: 1.8rem;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background: #fff;
  border: .05rem solid #0d84b3;
  border-radius: .1rem;
  outline: none;
  padding: .35rem .4rem;
  font-size: .8rem;
  line-height: 1rem;
  text-decoration: none;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
}

.btn:focus {
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.btn:focus, .btn:hover {
  background: #89d7f6;
  border-color: #0c79a5;
  text-decoration: none;
}

.btn:active, .btn.active {
  color: #fff;
  background: #0c79a5;
  border-color: #0a688d;
  text-decoration: none;
}

.btn:active.loading:after, .btn.active.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn[disabled], .btn:disabled, .btn.disabled {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.btn.btn-primary {
  color: #fff;
  background: #0d84b3;
  border-color: #0c79a5;
}

.btn.btn-primary:focus, .btn.btn-primary:hover {
  color: #fff;
  background: #0b729b;
  border-color: #0a688d;
}

.btn.btn-primary:active, .btn.btn-primary.active {
  color: #fff;
  background: #0b6b92;
  border-color: #0a6183;
}

.btn.btn-primary.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-success {
  color: #fff;
  background: #32b643;
  border-color: #2faa3f;
}

.btn.btn-success:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.btn.btn-success:focus, .btn.btn-success:hover {
  color: #fff;
  background: #30ae40;
  border-color: #2da23c;
}

.btn.btn-success:active, .btn.btn-success.active {
  color: #fff;
  background: #2a9a39;
  border-color: #278e34;
}

.btn.btn-success.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-error {
  color: #fff;
  background: #e85600;
  border-color: #d95000;
}

.btn.btn-error:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.btn.btn-error:focus, .btn.btn-error:hover {
  color: #fff;
  background: #de5200;
  border-color: #cf4d00;
}

.btn.btn-error:active, .btn.btn-error.active {
  color: #fff;
  background: #c44900;
  border-color: #b54300;
}

.btn.btn-error.loading:after {
  border-bottom-color: #fff;
  border-left-color: #fff;
}

.btn.btn-link {
  color: #0d84b3;
  background: none;
  border-color: #0000;
}

.btn.btn-link:focus, .btn.btn-link:hover, .btn.btn-link:active, .btn.btn-link.active {
  color: #0b729b;
}

.btn.btn-sm {
  height: 1.4rem;
  padding: .15rem .3rem;
  font-size: .7rem;
}

.btn.btn-lg {
  height: 2rem;
  padding: .45rem .6rem;
  font-size: .9rem;
}

.btn.btn-block {
  width: 100%;
  display: block;
}

.btn.btn-action {
  width: 1.8rem;
  padding-left: 0;
  padding-right: 0;
}

.btn.btn-action.btn-sm {
  width: 1.4rem;
}

.btn.btn-action.btn-lg {
  width: 2rem;
}

.btn.btn-clear {
  color: currentColor;
  height: 1rem;
  opacity: 1;
  width: 1rem;
  background: none;
  border: 0;
  margin-left: .2rem;
  margin-right: -2px;
  padding: .1rem;
  line-height: .8rem;
  text-decoration: none;
}

.btn.btn-clear:focus, .btn.btn-clear:hover {
  opacity: .95;
  background: #f1f1f180;
}

.btn.btn-clear:before {
  content: "✕";
}

.btn-group {
  flex-wrap: wrap;
  display: inline-flex;
}

.btn-group .btn {
  flex: 1 0 auto;
}

.btn-group .btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group .btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.btn-group .btn:focus, .btn-group .btn:hover, .btn-group .btn:active, .btn-group .btn.active {
  z-index: 1;
}

.btn-group.btn-group-block {
  display: flex;
}

.btn-group.btn-group-block .btn {
  flex: 1 0 0;
}

.form-group:not(:last-child) {
  margin-bottom: .4rem;
}

fieldset {
  margin-bottom: .8rem;
}

legend {
  margin-bottom: .8rem;
  font-size: .9rem;
  font-weight: 500;
}

.form-label {
  padding: .4rem 0;
  line-height: 1rem;
  display: block;
}

.form-label.label-sm {
  padding: .2rem 0;
  font-size: .7rem;
}

.form-label.label-lg {
  padding: .5rem 0;
  font-size: .9rem;
}

.form-input {
  -webkit-appearance: none;
  appearance: none;
  color: #2d2d2d;
  height: 1.8rem;
  max-width: 100%;
  width: 100%;
  background: #fff;
  border: .05rem solid #a0a0a0;
  border-radius: .1rem;
  outline: none;
  padding: .35rem .4rem;
  font-size: .8rem;
  line-height: 1rem;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: block;
  position: relative;
}

.form-input:focus {
  border-color: #0d84b3;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.form-input::placeholder {
  color: #868686;
}

.form-input.input-sm {
  height: 1.4rem;
  padding: .15rem .3rem;
  font-size: .7rem;
}

.form-input.input-lg {
  height: 2rem;
  padding: .45rem .6rem;
  font-size: .9rem;
}

.form-input.input-inline {
  vertical-align: middle;
  width: auto;
  display: inline-block;
}

.form-input[type="file"], textarea.form-input, textarea.form-input.input-lg, textarea.form-input.input-sm {
  height: auto;
}

.form-input-hint {
  color: #868686;
  margin-top: .2rem;
  font-size: .7rem;
}

.has-success .form-input-hint, .is-success + .form-input-hint {
  color: #32b643;
}

.has-error .form-input-hint, .is-error + .form-input-hint {
  color: #e85600;
}

.form-select {
  -webkit-appearance: none;
  appearance: none;
  color: inherit;
  height: 1.8rem;
  vertical-align: middle;
  width: 100%;
  background: #fff;
  border: .05rem solid #a0a0a0;
  border-radius: .1rem;
  outline: none;
  padding: .35rem .4rem;
  font-size: .8rem;
  line-height: 1rem;
}

.form-select:focus {
  border-color: #0d84b3;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.form-select::-ms-expand {
  display: none;
}

.form-select.select-sm {
  height: 1.4rem;
  padding: .15rem 1.1rem .15rem .3rem;
  font-size: .7rem;
}

.form-select.select-lg {
  height: 2rem;
  padding: .45rem 1.4rem .45rem .6rem;
  font-size: .9rem;
}

.form-select[size], .form-select[multiple] {
  height: auto;
  padding: .35rem .4rem;
}

.form-select[size] option, .form-select[multiple] option {
  padding: .1rem .2rem;
}

.form-select:not([multiple]):not([size]) {
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%204%205'%3E%3Cpath%20fill='%23667189'%20d='M2%200L0%202h4zm0%205L0%203h4z'/%3E%3C/svg%3E") right .35rem center / .4rem .5rem no-repeat;
  padding-right: 1.2rem;
}

.has-icon-left, .has-icon-right {
  position: relative;
}

.has-icon-left .form-icon, .has-icon-right .form-icon {
  height: .8rem;
  width: .8rem;
  z-index: 2;
  margin: 0 .35rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.has-icon-left .form-icon {
  left: .05rem;
}

.has-icon-left .form-input {
  padding-left: 1.5rem;
}

.has-icon-right .form-icon {
  right: .05rem;
}

.has-icon-right .form-input {
  padding-right: 1.5rem;
}

.form-checkbox, .form-radio, .form-switch {
  min-height: 1.4rem;
  margin: .2rem 0;
  padding: .2rem .4rem .2rem 1.2rem;
  line-height: 1rem;
  display: block;
  position: relative;
}

.form-checkbox input, .form-radio input, .form-switch input {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  position: absolute;
  overflow: hidden;
}

.form-checkbox input:focus + .form-icon, .form-radio input:focus + .form-icon, .form-switch input:focus + .form-icon {
  border-color: #0d84b3;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.form-checkbox input:checked + .form-icon, .form-radio input:checked + .form-icon, .form-switch input:checked + .form-icon {
  background: #0d84b3;
  border-color: #0d84b3;
}

.form-checkbox .form-icon, .form-radio .form-icon, .form-switch .form-icon {
  cursor: pointer;
  border: .05rem solid #a0a0a0;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  display: inline-block;
  position: absolute;
}

.form-checkbox.input-sm, .form-radio.input-sm, .form-switch.input-sm {
  margin: 0;
  font-size: .7rem;
}

.form-checkbox.input-lg, .form-radio.input-lg, .form-switch.input-lg {
  margin: .3rem 0;
  font-size: .9rem;
}

.form-checkbox .form-icon, .form-radio .form-icon {
  height: .8rem;
  width: .8rem;
  background: #fff;
  top: .3rem;
  left: 0;
}

.form-checkbox input:active + .form-icon, .form-radio input:active + .form-icon {
  background: #e9e9e9;
}

.form-checkbox .form-icon {
  border-radius: .1rem;
}

.form-checkbox input:checked + .form-icon:before {
  content: "";
  height: 9px;
  width: 6px;
  background-clip: padding-box;
  border: .1rem solid #fff;
  border-width: 0 .1rem .1rem 0;
  margin-top: -6px;
  margin-left: -3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: rotate(45deg);
}

.form-checkbox input:indeterminate + .form-icon {
  background: #0d84b3;
  border-color: #0d84b3;
}

.form-checkbox input:indeterminate + .form-icon:before {
  content: "";
  height: 2px;
  width: 10px;
  background: #fff;
  margin-top: -1px;
  margin-left: -5px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.form-radio .form-icon {
  border-radius: 50%;
}

.form-radio input:checked + .form-icon:before {
  content: "";
  height: 6px;
  width: 6px;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.form-switch {
  padding-left: 2rem;
}

.form-switch .form-icon {
  height: .9rem;
  width: 1.6rem;
  background: #868686 padding-box padding-box;
  border-radius: .45rem;
  top: .25rem;
  left: 0;
}

.form-switch .form-icon:before {
  content: "";
  height: .8rem;
  width: .8rem;
  background: #fff;
  border-radius: 50%;
  transition: background .2s, border .2s, box-shadow .2s, color .2s, left .2s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.form-switch input:checked + .form-icon:before {
  left: 14px;
}

.form-switch input:active + .form-icon:before {
  background: #f1f1f1;
}

.input-group {
  display: flex;
}

.input-group .input-group-addon {
  white-space: nowrap;
  background: #f1f1f1;
  border: .05rem solid #a0a0a0;
  border-radius: .1rem;
  padding: .35rem .4rem;
  line-height: 1rem;
}

.input-group .input-group-addon.addon-sm {
  padding: .15rem .3rem;
  font-size: .7rem;
}

.input-group .input-group-addon.addon-lg {
  padding: .45rem .6rem;
  font-size: .9rem;
}

.input-group .form-input, .input-group .form-select {
  width: 1%;
  flex: auto;
}

.input-group .input-group-btn {
  z-index: 1;
}

.input-group .form-input:first-child:not(:last-child), .input-group .form-select:first-child:not(:last-child), .input-group .input-group-addon:first-child:not(:last-child), .input-group .input-group-btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group .form-input:not(:first-child):not(:last-child), .input-group .form-select:not(:first-child):not(:last-child), .input-group .input-group-addon:not(:first-child):not(:last-child), .input-group .input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:last-child:not(:first-child), .input-group .form-select:last-child:not(:first-child), .input-group .input-group-addon:last-child:not(:first-child), .input-group .input-group-btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -.05rem;
}

.input-group .form-input:focus, .input-group .form-select:focus, .input-group .input-group-addon:focus, .input-group .input-group-btn:focus {
  z-index: 2;
}

.input-group .form-select {
  width: auto;
}

.input-group.input-inline {
  display: inline-flex;
}

.has-success .form-input, .form-input.is-success, .has-success .form-select, .form-select.is-success {
  background: #f9fdfa;
  border-color: #32b643;
}

.has-success .form-input:focus, .form-input.is-success:focus, .has-success .form-select:focus, .form-select.is-success:focus {
  box-shadow: 0 0 0 .1rem #32b64333;
}

.has-error .form-input, .form-input.is-error, .has-error .form-select, .form-select.is-error {
  background: #fffaf7;
  border-color: #e85600;
}

.has-error .form-input:focus, .form-input.is-error:focus, .has-error .form-select:focus, .form-select.is-error:focus {
  box-shadow: 0 0 0 .1rem #e8560033;
}

.has-error .form-checkbox .form-icon, .form-checkbox.is-error .form-icon, .has-error .form-radio .form-icon, .form-radio.is-error .form-icon, .has-error .form-switch .form-icon, .form-switch.is-error .form-icon {
  border-color: #e85600;
}

.has-error .form-checkbox input:checked + .form-icon, .form-checkbox.is-error input:checked + .form-icon, .has-error .form-radio input:checked + .form-icon, .form-radio.is-error input:checked + .form-icon, .has-error .form-switch input:checked + .form-icon, .form-switch.is-error input:checked + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.has-error .form-checkbox input:focus + .form-icon, .form-checkbox.is-error input:focus + .form-icon, .has-error .form-radio input:focus + .form-icon, .form-radio.is-error input:focus + .form-icon, .has-error .form-switch input:focus + .form-icon, .form-switch.is-error input:focus + .form-icon {
  border-color: #e85600;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.has-error .form-checkbox input:indeterminate + .form-icon, .form-checkbox.is-error input:indeterminate + .form-icon {
  background: #e85600;
  border-color: #e85600;
}

.form-input:not(:placeholder-shown):invalid {
  border-color: #e85600;
}

.form-input:not(:placeholder-shown):invalid:focus {
  background: #fffaf7;
  box-shadow: 0 0 0 .1rem #e8560033;
}

.form-input:not(:placeholder-shown):invalid + .form-input-hint {
  color: #e85600;
}

.form-input:disabled, .form-input.disabled, .form-select:disabled, .form-select.disabled {
  cursor: not-allowed;
  opacity: .5;
  background-color: #e9e9e9;
}

.form-input[readonly] {
  background-color: #f1f1f1;
}

input:disabled + .form-icon, input.disabled + .form-icon {
  cursor: not-allowed;
  opacity: .5;
  background: #e9e9e9;
}

.form-switch input:disabled + .form-icon:before, .form-switch input.disabled + .form-icon:before {
  background: #fff;
}

.form-horizontal {
  padding: .4rem 0;
}

.form-horizontal .form-group {
  flex-wrap: wrap;
  display: flex;
}

.form-inline {
  display: inline-block;
}

.label {
  color: #3a3a3a;
  background: #e9e9e9;
  border-radius: .1rem;
  padding: .1rem .2rem;
  line-height: 1.25;
  display: inline-block;
}

.label.label-rounded {
  border-radius: 5rem;
  padding-left: .4rem;
  padding-right: .4rem;
}

.label.label-primary {
  color: #fff;
  background: #0d84b3;
}

.label.label-secondary {
  color: #0d84b3;
  background: #89d7f6;
}

.label.label-success {
  color: #fff;
  background: #32b643;
}

.label.label-warning {
  color: #fff;
  background: #ffb700;
}

.label.label-error {
  color: #fff;
  background: #e85600;
}

code {
  color: #e06870;
  background: #fff;
  border-radius: .1rem;
  padding: .1rem .2rem;
  font-size: 85%;
  line-height: 1.25;
}

.code {
  color: #2d2d2d;
  border-radius: .1rem;
  position: relative;
}

.code:before {
  color: #868686;
  content: attr(data-lang);
  font-size: .7rem;
  position: absolute;
  top: .1rem;
  right: .4rem;
}

.code code {
  color: inherit;
  width: 100%;
  background: #f1f1f1;
  padding: 1rem;
  line-height: 1.5;
  display: block;
  overflow-x: auto;
}

.img-responsive {
  height: auto;
  max-width: 100%;
  display: block;
}

.img-fit-cover {
  object-fit: cover;
}

.img-fit-contain {
  object-fit: contain;
}

.video-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.video-responsive:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.video-responsive iframe, .video-responsive object, .video-responsive embed {
  height: 100%;
  width: 100%;
  border: 0;
  position: absolute;
  inset: 0;
}

video.video-responsive {
  height: auto;
  max-width: 100%;
}

video.video-responsive:before {
  content: none;
}

.video-responsive-4-3:before {
  padding-bottom: 75%;
}

.video-responsive-1-1:before {
  padding-bottom: 100%;
}

.figure {
  margin: 0 0 .4rem;
}

.figure .figure-caption {
  color: #464646;
  margin-top: .4rem;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: .4rem;
  padding-right: .4rem;
}

.container.grid-xl {
  max-width: 1296px;
}

.container.grid-lg {
  max-width: 976px;
}

.container.grid-md {
  max-width: 856px;
}

.container.grid-sm {
  max-width: 616px;
}

.container.grid-xs {
  max-width: 496px;
}

.show-xs, .show-sm, .show-md, .show-lg, .show-xl {
  display: none !important;
}

.cols, .columns {
  flex-wrap: wrap;
  margin-left: -.4rem;
  margin-right: -.4rem;
  display: flex;
}

.cols.col-gapless, .columns.col-gapless {
  margin-left: 0;
  margin-right: 0;
}

.cols.col-gapless > .column, .columns.col-gapless > .column {
  padding-left: 0;
  padding-right: 0;
}

.cols.col-oneline, .columns.col-oneline {
  flex-wrap: nowrap;
  overflow-x: auto;
}

[class~="col-"], .column {
  max-width: 100%;
  flex: 1;
  padding-left: .4rem;
  padding-right: .4rem;
}

[class~="col-"].col-12, [class~="col-"].col-11, [class~="col-"].col-10, [class~="col-"].col-9, [class~="col-"].col-8, [class~="col-"].col-7, [class~="col-"].col-6, [class~="col-"].col-5, [class~="col-"].col-4, [class~="col-"].col-3, [class~="col-"].col-2, [class~="col-"].col-1, [class~="col-"].col-auto, .column.col-12, .column.col-11, .column.col-10, .column.col-9, .column.col-8, .column.col-7, .column.col-6, .column.col-5, .column.col-4, .column.col-3, .column.col-2, .column.col-1, .column.col-auto {
  flex: none;
}

.col-12 {
  width: 100%;
}

.col-11 {
  width: 91.6667%;
}

.col-10 {
  width: 83.3333%;
}

.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.6667%;
}

.col-7 {
  width: 58.3333%;
}

.col-6 {
  width: 50%;
}

.col-5 {
  width: 41.6667%;
}

.col-4 {
  width: 33.3333%;
}

.col-3 {
  width: 25%;
}

.col-2 {
  width: 16.6667%;
}

.col-1 {
  width: 8.33333%;
}

.col-auto {
  max-width: none;
  width: auto;
  flex: none;
}

.col-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.col-ml-auto {
  margin-left: auto;
}

.col-mr-auto {
  margin-right: auto;
}

@media (max-width: 1280px) {
  .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-xl-auto {
    flex: none;
  }

  .col-xl-12 {
    width: 100%;
  }

  .col-xl-11 {
    width: 91.6667%;
  }

  .col-xl-10 {
    width: 83.3333%;
  }

  .col-xl-9 {
    width: 75%;
  }

  .col-xl-8 {
    width: 66.6667%;
  }

  .col-xl-7 {
    width: 58.3333%;
  }

  .col-xl-6 {
    width: 50%;
  }

  .col-xl-5 {
    width: 41.6667%;
  }

  .col-xl-4 {
    width: 33.3333%;
  }

  .col-xl-3 {
    width: 25%;
  }

  .col-xl-2 {
    width: 16.6667%;
  }

  .col-xl-1 {
    width: 8.33333%;
  }

  .col-xl-auto {
    width: auto;
  }

  .hide-xl {
    display: none !important;
  }

  .show-xl {
    display: block !important;
  }
}

@media (max-width: 960px) {
  .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-lg-auto {
    flex: none;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.6667%;
  }

  .col-lg-10 {
    width: 83.3333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.6667%;
  }

  .col-lg-7 {
    width: 58.3333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.6667%;
  }

  .col-lg-4 {
    width: 33.3333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.6667%;
  }

  .col-lg-1 {
    width: 8.33333%;
  }

  .col-lg-auto {
    width: auto;
  }

  .hide-lg {
    display: none !important;
  }

  .show-lg {
    display: block !important;
  }
}

@media (max-width: 840px) {
  .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-md-auto {
    flex: none;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.6667%;
  }

  .col-md-10 {
    width: 83.3333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.6667%;
  }

  .col-md-7 {
    width: 58.3333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.6667%;
  }

  .col-md-4 {
    width: 33.3333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.6667%;
  }

  .col-md-1 {
    width: 8.33333%;
  }

  .col-md-auto {
    width: auto;
  }

  .hide-md {
    display: none !important;
  }

  .show-md {
    display: block !important;
  }
}

@media (max-width: 600px) {
  .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-sm-auto {
    flex: none;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.6667%;
  }

  .col-sm-10 {
    width: 83.3333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.6667%;
  }

  .col-sm-7 {
    width: 58.3333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.6667%;
  }

  .col-sm-4 {
    width: 33.3333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.6667%;
  }

  .col-sm-1 {
    width: 8.33333%;
  }

  .col-sm-auto {
    width: auto;
  }

  .hide-sm {
    display: none !important;
  }

  .show-sm {
    display: block !important;
  }
}

@media (max-width: 480px) {
  .col-xs-12, .col-xs-11, .col-xs-10, .col-xs-9, .col-xs-8, .col-xs-7, .col-xs-6, .col-xs-5, .col-xs-4, .col-xs-3, .col-xs-2, .col-xs-1, .col-xs-auto {
    flex: none;
  }

  .col-xs-12 {
    width: 100%;
  }

  .col-xs-11 {
    width: 91.6667%;
  }

  .col-xs-10 {
    width: 83.3333%;
  }

  .col-xs-9 {
    width: 75%;
  }

  .col-xs-8 {
    width: 66.6667%;
  }

  .col-xs-7 {
    width: 58.3333%;
  }

  .col-xs-6 {
    width: 50%;
  }

  .col-xs-5 {
    width: 41.6667%;
  }

  .col-xs-4 {
    width: 33.3333%;
  }

  .col-xs-3 {
    width: 25%;
  }

  .col-xs-2 {
    width: 16.6667%;
  }

  .col-xs-1 {
    width: 8.33333%;
  }

  .col-xs-auto {
    width: auto;
  }

  .hide-xs {
    display: none !important;
  }

  .show-xs {
    display: block !important;
  }
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
}

.hero.hero-sm {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hero.hero-lg {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.hero .hero-body {
  padding: .4rem;
}

.navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
}

.navbar .navbar-section {
  flex: 1 0 0;
  align-items: center;
  display: flex;
}

.navbar .navbar-section:not(:first-child):last-child {
  justify-content: flex-end;
}

.navbar .navbar-center {
  flex: none;
  align-items: center;
  display: flex;
}

.navbar .navbar-brand {
  font-size: .9rem;
  text-decoration: none;
}

.accordion input:checked ~ .accordion-header > .icon:first-child, .accordion[open] .accordion-header > .icon:first-child {
  transform: rotate(90deg);
}

.accordion input:checked ~ .accordion-body, .accordion[open] .accordion-body {
  max-height: 50rem;
}

.accordion .accordion-header {
  padding: .2rem .4rem;
  display: block;
}

.accordion .accordion-header .icon {
  transition: transform .25s;
}

.accordion .accordion-body {
  max-height: 0;
  margin-bottom: .4rem;
  transition: max-height .25s;
  overflow: hidden;
}

summary.accordion-header::-webkit-details-marker {
  display: none;
}

.avatar {
  height: 1.6rem;
  width: 1.6rem;
  color: #ffffffd9;
  vertical-align: middle;
  background: #0d84b3;
  border-radius: 50%;
  margin: 0;
  font-size: .8rem;
  font-weight: 300;
  line-height: 1.25;
  display: inline-block;
  position: relative;
}

.avatar.avatar-xs {
  height: .8rem;
  width: .8rem;
  font-size: .4rem;
}

.avatar.avatar-sm {
  height: 1.2rem;
  width: 1.2rem;
  font-size: .6rem;
}

.avatar.avatar-lg {
  height: 2.4rem;
  width: 2.4rem;
  font-size: 1.2rem;
}

.avatar.avatar-xl {
  height: 3.2rem;
  width: 3.2rem;
  font-size: 1.6rem;
}

.avatar img {
  height: 100%;
  width: 100%;
  z-index: 1;
  border-radius: 50%;
  position: relative;
}

.avatar .avatar-icon, .avatar .avatar-presence {
  height: 50%;
  width: 50%;
  z-index: 2;
  background: #fff;
  padding: .1rem;
  position: absolute;
  bottom: 14.64%;
  right: 14.64%;
  transform: translate(50%, 50%);
}

.avatar .avatar-presence {
  height: .5em;
  width: .5em;
  background: #868686;
  border-radius: 50%;
  box-shadow: 0 0 0 .1rem #fff;
}

.avatar .avatar-presence.online {
  background: #32b643;
}

.avatar .avatar-presence.busy {
  background: #e85600;
}

.avatar .avatar-presence.away {
  background: #ffb700;
}

.avatar[data-initial]:before {
  color: currentColor;
  content: attr(data-initial);
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.badge {
  white-space: nowrap;
  position: relative;
}

.badge[data-badge]:after, .badge:not([data-badge]):after {
  color: #fff;
  content: attr(data-badge);
  background: #0d84b3 padding-box padding-box;
  border-radius: .5rem;
  display: inline-block;
  transform: translate(-.05rem, -.5rem);
  box-shadow: 0 0 0 .1rem #fff;
}

.badge[data-badge]:after {
  height: .9rem;
  min-width: .9rem;
  text-align: center;
  white-space: nowrap;
  padding: .1rem .2rem;
  font-size: .7rem;
  line-height: 1;
}

.badge:not([data-badge]):after, .badge[data-badge=""]:after {
  height: 6px;
  min-width: 6px;
  width: 6px;
  padding: 0;
}

.badge.btn:after {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
}

.badge.avatar:after {
  z-index: 100;
  position: absolute;
  top: 14.64%;
  right: 14.64%;
  transform: translate(50%, -50%);
}

.breadcrumb {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
}

.breadcrumb .breadcrumb-item {
  color: #464646;
  margin: 0;
  padding: .2rem 0;
  display: inline-block;
}

.breadcrumb .breadcrumb-item:not(:last-child) {
  margin-right: .2rem;
}

.breadcrumb .breadcrumb-item:not(:last-child) a {
  color: #464646;
}

.breadcrumb .breadcrumb-item:not(:first-child):before {
  color: #464646;
  content: "/";
  padding-right: .4rem;
}

.bar {
  height: .8rem;
  width: 100%;
  background: #e9e9e9;
  border-radius: .1rem;
  flex-wrap: nowrap;
  display: flex;
}

.bar.bar-sm {
  height: .2rem;
}

.bar .bar-item {
  color: #fff;
  height: 100%;
  text-align: center;
  width: 0;
  background: #0d84b3;
  flex-shrink: 0;
  font-size: .7rem;
  line-height: .8rem;
  display: block;
  position: relative;
}

.bar .bar-item:first-child {
  border-top-left-radius: .1rem;
  border-bottom-left-radius: .1rem;
}

.bar .bar-item:last-child {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
  flex-shrink: 1;
}

.bar-slider {
  height: .1rem;
  margin: .4rem 0;
  position: relative;
}

.bar-slider .bar-item {
  padding: 0;
  position: absolute;
  left: 0;
}

.bar-slider .bar-item:not(:last-child):first-child {
  z-index: 1;
  background: #e9e9e9;
}

.bar-slider .bar-slider-btn {
  height: .6rem;
  width: .6rem;
  background: #0d84b3;
  border: 0;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.bar-slider .bar-slider-btn:active {
  box-shadow: 0 0 0 .1rem #0d84b3;
}

.card {
  background: #fff;
  border: .05rem solid #b9b9b9;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.card .card-header, .card .card-body, .card .card-footer {
  padding: .8rem .8rem 0;
}

.card .card-header:last-child, .card .card-body:last-child, .card .card-footer:last-child {
  padding-bottom: .8rem;
}

.card .card-body {
  flex: auto;
}

.card .card-image {
  padding-top: .8rem;
}

.card .card-image:first-child {
  padding-top: 0;
}

.card .card-image:first-child img {
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.card .card-image:last-child img {
  border-bottom-right-radius: .1rem;
  border-bottom-left-radius: .1rem;
}

.chip {
  height: 1.2rem;
  max-width: 320px;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  background: #e9e9e9;
  border-radius: 5rem;
  align-items: center;
  margin: .1rem;
  padding: .2rem .4rem;
  font-size: 90%;
  line-height: .8rem;
  text-decoration: none;
  display: inline-flex;
  overflow: hidden;
}

.chip.active {
  color: #fff;
  background: #0d84b3;
}

.chip .avatar {
  margin-left: -.4rem;
  margin-right: .2rem;
}

.chip .btn-clear {
  border-radius: 50%;
  transform: scale(.75);
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .menu {
  max-height: 50vh;
  animation: .15s slide-down;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: auto;
}

.dropdown.dropdown-right .menu {
  left: auto;
  right: 0;
}

.dropdown.active .menu, .dropdown .dropdown-toggle:focus + .menu, .dropdown .menu:hover {
  display: block;
}

.dropdown .btn-group .dropdown-toggle:nth-last-child(2) {
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
}

.empty {
  color: #464646;
  text-align: center;
  background: #f1f1f1;
  border-radius: .1rem;
  padding: 3.2rem 1.6rem;
}

.empty .empty-icon {
  margin-bottom: .8rem;
}

.empty .empty-title, .empty .empty-subtitle {
  margin: .4rem auto;
}

.empty .empty-action {
  margin-top: .8rem;
}

.menu {
  min-width: 180px;
  z-index: 300;
  background: #fff;
  border-radius: .1rem;
  margin: 0;
  padding: .4rem;
  list-style: none;
  transform: translateY(.2rem);
  box-shadow: 0 .05rem .2rem #2020204d;
}

.menu.menu-nav {
  box-shadow: none;
  background: none;
}

.menu .menu-item {
  margin-top: 0;
  padding: 0 .4rem;
  text-decoration: none;
  position: relative;
}

.menu .menu-item > a {
  color: inherit;
  border-radius: .1rem;
  margin: 0 -.4rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: block;
}

.menu .menu-item > a:focus, .menu .menu-item > a:hover, .menu .menu-item > a:active, .menu .menu-item > a.active {
  color: #0d84b3;
  background: #89d7f6;
}

.menu .menu-item .form-checkbox, .menu .menu-item .form-radio, .menu .menu-item .form-switch {
  margin: .1rem 0;
}

.menu .menu-item + .menu-item {
  margin-top: .2rem;
}

.menu .menu-badge {
  height: 100%;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.menu .menu-badge .label {
  margin-right: .4rem;
}

.modal {
  opacity: 0;
  justify-content: center;
  align-items: center;
  padding: .4rem;
  display: none;
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.modal:target, .modal.active {
  opacity: 1;
  z-index: 400;
  display: flex;
}

.modal:target .modal-overlay, .modal.active .modal-overlay {
  cursor: default;
  background: #f1f1f1bf;
  display: block;
  position: absolute;
  inset: 0;
}

.modal:target .modal-container, .modal.active .modal-container {
  z-index: 1;
  animation: .2s slide-down;
}

.modal.modal-sm .modal-container {
  max-width: 320px;
  padding: 0 .4rem;
}

.modal.modal-lg .modal-overlay {
  background: #fff;
}

.modal.modal-lg .modal-container {
  box-shadow: none;
  max-width: 960px;
}

.modal-container {
  max-height: 75vh;
  max-width: 640px;
  width: 100%;
  background: #fff;
  border-radius: .1rem;
  flex-direction: column;
  padding: 0 .8rem;
  display: flex;
  box-shadow: 0 .2rem .5rem #2020204d;
}

.modal-container.modal-fullheight {
  max-height: 100vh;
}

.modal-container .modal-header {
  color: #202020;
  padding: .8rem;
}

.modal-container .modal-body {
  padding: .8rem;
  position: relative;
  overflow-y: auto;
}

.modal-container .modal-footer {
  text-align: right;
  padding: .8rem;
}

.nav {
  flex-direction: column;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.nav .nav-item a {
  color: #464646;
  padding: .2rem .4rem;
  text-decoration: none;
}

.nav .nav-item a:focus, .nav .nav-item a:hover {
  color: #0d84b3;
}

.nav .nav-item.active > a {
  color: #2d2d2d;
  font-weight: bold;
}

.nav .nav-item.active > a:focus, .nav .nav-item.active > a:hover {
  color: #0d84b3;
}

.nav .nav {
  margin-bottom: .4rem;
  margin-left: .8rem;
}

.pagination {
  margin: .2rem 0;
  padding: .2rem 0;
  list-style: none;
  display: flex;
}

.pagination .page-item {
  margin: .2rem .05rem;
}

.pagination .page-item span {
  padding: .2rem;
  display: inline-block;
}

.pagination .page-item a {
  border-radius: .1rem;
  padding: .2rem .4rem;
  text-decoration: none;
  display: inline-block;
}

.pagination .page-item a:focus, .pagination .page-item a:hover {
  color: #0d84b3;
}

.pagination .page-item.disabled a {
  cursor: default;
  opacity: .5;
  pointer-events: none;
}

.pagination .page-item.active a {
  color: #fff;
  background: #0d84b3;
}

.pagination .page-item.page-prev, .pagination .page-item.page-next {
  flex: 1 0 50%;
}

.pagination .page-item.page-next {
  text-align: right;
}

.pagination .page-item .page-item-title {
  margin: 0;
}

.pagination .page-item .page-item-subtitle {
  opacity: .5;
  margin: 0;
}

.panel {
  border: .05rem solid #b9b9b9;
  border-radius: .1rem;
  flex-direction: column;
  display: flex;
}

.panel .panel-header, .panel .panel-footer {
  flex: none;
  padding: .8rem;
}

.panel .panel-nav {
  flex: none;
}

.panel .panel-body {
  flex: auto;
  padding: 0 .8rem;
  overflow-y: auto;
}

.popover {
  display: inline-block;
  position: relative;
}

.popover .popover-container {
  opacity: 0;
  width: 320px;
  z-index: 300;
  padding: .4rem;
  transition: transform .2s;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%)scale(0);
}

.popover :focus + .popover-container, .popover:hover .popover-container {
  opacity: 1;
  display: block;
  transform: translate(-50%, -100%)scale(1);
}

.popover.popover-right .popover-container {
  top: 50%;
  left: 100%;
}

.popover.popover-right :focus + .popover-container, .popover.popover-right:hover .popover-container {
  transform: translate(0, -50%)scale(1);
}

.popover.popover-bottom .popover-container {
  top: 100%;
  left: 50%;
}

.popover.popover-bottom :focus + .popover-container, .popover.popover-bottom:hover .popover-container {
  transform: translate(-50%)scale(1);
}

.popover.popover-left .popover-container {
  top: 50%;
  left: 0;
}

.popover.popover-left :focus + .popover-container, .popover.popover-left:hover .popover-container {
  transform: translate(-100%, -50%)scale(1);
}

.popover .card {
  border: 0;
  box-shadow: 0 .2rem .5rem #2020204d;
}

.step {
  width: 100%;
  flex-wrap: nowrap;
  margin: .2rem 0;
  list-style: none;
  display: flex;
}

.step .step-item {
  min-height: 1rem;
  text-align: center;
  flex: 1 1 0;
  margin-top: 0;
  position: relative;
}

.step .step-item:not(:first-child):before {
  content: "";
  height: 2px;
  width: 100%;
  background: #0d84b3;
  position: absolute;
  top: 9px;
  left: -50%;
}

.step .step-item a {
  color: #0d84b3;
  padding: 20px 10px 0;
  text-decoration: none;
  display: inline-block;
}

.step .step-item a:before {
  content: "";
  height: .6rem;
  width: .6rem;
  z-index: 1;
  background: #0d84b3;
  border: .1rem solid #fff;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.step .step-item.active a:before {
  background: #fff;
  border: .1rem solid #0d84b3;
}

.step .step-item.active ~ .step-item:before {
  background: #b9b9b9;
}

.step .step-item.active ~ .step-item a {
  color: #868686;
}

.step .step-item.active ~ .step-item a:before {
  background: #b9b9b9;
}

.tab {
  border-bottom: .05rem solid #b9b9b9;
  flex-wrap: wrap;
  align-items: center;
  margin: .2rem 0 .15rem;
  list-style: none;
  display: flex;
}

.tab .tab-item {
  margin-top: 0;
}

.tab .tab-item a {
  color: inherit;
  border-bottom: .1rem solid #0000;
  margin: 0 .4rem 0 0;
  padding: .4rem .2rem .3rem;
  text-decoration: none;
  display: block;
}

.tab .tab-item a:focus, .tab .tab-item a:hover {
  color: #0d84b3;
}

.tab .tab-item.active a, .tab .tab-item a.active {
  color: #0d84b3;
  border-bottom-color: #0d84b3;
}

.tab .tab-item.tab-action {
  text-align: right;
  flex: 1 0 auto;
}

.tab .tab-item .btn-clear {
  margin-top: -.2rem;
}

.tab.tab-block .tab-item {
  text-align: center;
  flex: 1 0 0;
}

.tab.tab-block .tab-item a {
  margin: 0;
}

.tab.tab-block .tab-item .badge[data-badge]:after {
  position: absolute;
  top: .1rem;
  right: .1rem;
  transform: translate(0);
}

.tab:not(.tab-block) .badge {
  padding-right: 0;
}

.tile {
  align-content: space-between;
  align-items: flex-start;
  display: flex;
}

.tile .tile-icon, .tile .tile-action {
  flex: none;
}

.tile .tile-content {
  flex: auto;
}

.tile .tile-content:not(:first-child) {
  padding-left: .4rem;
}

.tile .tile-content:not(:last-child) {
  padding-right: .4rem;
}

.tile .tile-title, .tile .tile-subtitle {
  line-height: 1rem;
}

.tile.tile-centered {
  align-items: center;
}

.tile.tile-centered .tile-content {
  overflow: hidden;
}

.tile.tile-centered .tile-title, .tile.tile-centered .tile-subtitle {
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  overflow: hidden;
}

.toast {
  color: #fff;
  width: 100%;
  background: #202020f2;
  border: .05rem solid #202020;
  border-radius: .1rem;
  padding: .4rem;
  display: block;
}

.toast.toast-primary {
  background: #0d84b3f2;
  border-color: #0d84b3;
}

.toast.toast-success {
  background: #32b643f2;
  border-color: #32b643;
}

.toast.toast-warning {
  background: #ffb700f2;
  border-color: #ffb700;
}

.toast.toast-error {
  background: #e85600f2;
  border-color: #e85600;
}

.toast a {
  color: #fff;
  text-decoration: underline;
}

.toast a:focus, .toast a:hover, .toast a:active, .toast a.active {
  opacity: .75;
}

.toast .btn-clear {
  margin: .1rem;
}

.toast p:last-child {
  margin-bottom: 0;
}

.tooltip {
  position: relative;
}

.tooltip:after {
  color: #fff;
  content: attr(data-tooltip);
  max-width: 320px;
  opacity: 0;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: pre;
  z-index: 300;
  background: #202020f2;
  border-radius: .1rem;
  padding: .2rem .4rem;
  font-size: .7rem;
  transition: opacity .2s, transform .2s;
  display: block;
  position: absolute;
  bottom: 100%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, .4rem);
}

.tooltip:focus:after, .tooltip:hover:after {
  opacity: 1;
  transform: translate(-50%, -.2rem);
}

.tooltip[disabled], .tooltip.disabled {
  pointer-events: auto;
}

.tooltip.tooltip-right:after {
  bottom: 50%;
  left: 100%;
  transform: translate(-.2rem, 50%);
}

.tooltip.tooltip-right:focus:after, .tooltip.tooltip-right:hover:after {
  transform: translate(.2rem, 50%);
}

.tooltip.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  transform: translate(-50%, -.4rem);
}

.tooltip.tooltip-bottom:focus:after, .tooltip.tooltip-bottom:hover:after {
  transform: translate(-50%, .2rem);
}

.tooltip.tooltip-left:after {
  bottom: 50%;
  left: auto;
  right: 100%;
  transform: translate(.4rem, 50%);
}

.tooltip.tooltip-left:focus:after, .tooltip.tooltip-left:hover:after {
  transform: translate(-.2rem, 50%);
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-1.6rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-primary {
  color: #0d84b3 !important;
}

a.text-primary:focus, a.text-primary:hover {
  color: #0b729b;
}

a.text-primary:visited {
  color: #0f96cb;
}

.text-secondary {
  color: #7bd3f5 !important;
}

a.text-secondary:focus, a.text-secondary:hover {
  color: #63cbf4;
}

a.text-secondary:visited {
  color: #92dbf7;
}

.text-gray {
  color: #868686 !important;
}

a.text-gray:focus, a.text-gray:hover {
  color: #797979;
}

a.text-gray:visited {
  color: #939393;
}

.text-light {
  color: #fff !important;
}

a.text-light:focus, a.text-light:hover {
  color: #f2f2f2;
}

a.text-light:visited {
  color: #fff;
}

.text-dark {
  color: #2d2d2d !important;
}

a.text-dark:focus, a.text-dark:hover {
  color: #202020;
}

a.text-dark:visited {
  color: #3a3a3a;
}

.text-success {
  color: #32b643 !important;
}

a.text-success:focus, a.text-success:hover {
  color: #2da23c;
}

a.text-success:visited {
  color: #39c94b;
}

.text-warning {
  color: #ffb700 !important;
}

a.text-warning:focus, a.text-warning:hover {
  color: #e6a500;
}

a.text-warning:visited {
  color: #ffbe1a;
}

.text-error {
  color: #e85600 !important;
}

a.text-error:focus, a.text-error:hover {
  color: #cf4d00;
}

a.text-error:visited {
  color: #ff6003;
}

.bg-primary {
  color: #fff;
  background: #0d84b3 !important;
}

.bg-secondary {
  background: #89d7f6 !important;
}

.bg-dark {
  color: #fff;
  background: #202020 !important;
}

.bg-gray {
  background: #f1f1f1 !important;
}

.bg-success {
  color: #fff;
  background: #32b643 !important;
}

.bg-warning {
  color: #fff;
  background: #ffb700 !important;
}

.bg-error {
  color: #fff;
  background: #e85600 !important;
}

.c-hand {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.c-zoom-in {
  cursor: zoom-in;
}

.c-zoom-out {
  cursor: zoom-out;
}

.c-not-allowed {
  cursor: not-allowed;
}

.c-auto {
  cursor: auto;
}

.d-block {
  display: block;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none, .d-hide {
  display: none !important;
}

.d-visible {
  visibility: visible;
}

.d-invisible {
  visibility: hidden;
}

.text-hide {
  color: #0000;
  text-shadow: none;
  background: none;
  border: 0;
  font-size: 0;
  line-height: 0;
}

.text-assistive {
  clip: rect(0, 0, 0, 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.divider, .divider-vert {
  display: block;
  position: relative;
}

.divider[data-content]:after, .divider-vert[data-content]:after {
  color: #868686;
  content: attr(data-content);
  background: #fff;
  padding: 0 .4rem;
  font-size: .7rem;
  display: inline-block;
  transform: translateY(-.65rem);
}

.divider {
  height: .05rem;
  border-top: .05rem solid #cdcdcd;
  margin: .4rem 0;
}

.divider[data-content] {
  margin: .8rem 0;
}

.divider-vert {
  padding: .8rem;
  display: block;
}

.divider-vert:before {
  content: "";
  border-left: .05rem solid #b9b9b9;
  display: block;
  position: absolute;
  top: .4rem;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.divider-vert[data-content]:after {
  padding: .2rem 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loading {
  min-height: .8rem;
  pointer-events: none;
  position: relative;
  color: #0000 !important;
}

.loading:after {
  content: "";
  height: .8rem;
  opacity: 1;
  width: .8rem;
  z-index: 1;
  background: none;
  border: .1rem solid #0000;
  border-color: #0000 #0000 #0d84b3 #0d84b3;
  border-radius: 50%;
  margin-top: -.4rem;
  margin-left: -.4rem;
  padding: 0;
  animation: .5s linear infinite loading;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.loading.loading-lg {
  min-height: 2rem;
}

.loading.loading-lg:after {
  height: 1.6rem;
  width: 1.6rem;
  margin-top: -.8rem;
  margin-left: -.8rem;
}

.clearfix:after {
  clear: both;
  content: "";
  display: table;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.p-relative {
  position: relative !important;
}

.p-absolute {
  position: absolute !important;
}

.p-fixed {
  position: fixed !important;
}

.p-sticky {
  position: sticky !important;
}

.p-centered {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.flex-centered {
  justify-content: center;
  align-items: center;
  display: flex;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.m-1 {
  margin: .2rem !important;
}

.mb-1 {
  margin-bottom: .2rem !important;
}

.ml-1 {
  margin-left: .2rem !important;
}

.mr-1 {
  margin-right: .2rem !important;
}

.mt-1 {
  margin-top: .2rem !important;
}

.mx-1 {
  margin-left: .2rem !important;
  margin-right: .2rem !important;
}

.my-1 {
  margin-top: .2rem !important;
  margin-bottom: .2rem !important;
}

.m-2 {
  margin: .4rem !important;
}

.mb-2 {
  margin-bottom: .4rem !important;
}

.ml-2 {
  margin-left: .4rem !important;
}

.mr-2 {
  margin-right: .4rem !important;
}

.mt-2 {
  margin-top: .4rem !important;
}

.mx-2 {
  margin-left: .4rem !important;
  margin-right: .4rem !important;
}

.my-2 {
  margin-top: .4rem !important;
  margin-bottom: .4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.p-1 {
  padding: .2rem !important;
}

.pb-1 {
  padding-bottom: .2rem !important;
}

.pl-1 {
  padding-left: .2rem !important;
}

.pr-1 {
  padding-right: .2rem !important;
}

.pt-1 {
  padding-top: .2rem !important;
}

.px-1 {
  padding-left: .2rem !important;
  padding-right: .2rem !important;
}

.py-1 {
  padding-top: .2rem !important;
  padding-bottom: .2rem !important;
}

.p-2 {
  padding: .4rem !important;
}

.pb-2 {
  padding-bottom: .4rem !important;
}

.pl-2 {
  padding-left: .4rem !important;
}

.pr-2 {
  padding-right: .4rem !important;
}

.pt-2 {
  padding-top: .4rem !important;
}

.px-2 {
  padding-left: .4rem !important;
  padding-right: .4rem !important;
}

.py-2 {
  padding-top: .4rem !important;
  padding-bottom: .4rem !important;
}

.s-rounded {
  border-radius: .1rem;
}

.s-circle {
  border-radius: 50%;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-normal {
  font-weight: normal;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-large {
  font-size: 1.2em;
}

.text-small {
  font-size: .9em;
}

.text-tiny {
  font-size: .8em;
}

.text-muted {
  opacity: .8;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-clip {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

.text-break {
  -webkit-hyphens: auto;
  hyphens: auto;
  word-break: break-word;
  word-wrap: break-word;
}

.icon {
  box-sizing: border-box;
  font-size: inherit;
  height: 1em;
  text-indent: -9999px;
  vertical-align: middle;
  width: 1em;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.icon:before, .icon:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon.icon-2x {
  font-size: 1.6rem;
}

.icon.icon-3x {
  font-size: 2.4rem;
}

.icon.icon-4x {
  font-size: 3.2rem;
}

.accordion .icon, .btn .icon, .toast .icon, .menu .icon {
  vertical-align: -10%;
}

.btn-lg .icon {
  vertical-align: -15%;
}

.icon-arrow-down:before, .icon-arrow-left:before, .icon-arrow-right:before, .icon-arrow-up:before, .icon-downward:before, .icon-back:before, .icon-forward:before, .icon-upward:before {
  height: .65em;
  width: .65em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
}

.icon-arrow-down:before {
  transform: translate(-50%, -75%)rotate(225deg);
}

.icon-arrow-left:before {
  transform: translate(-25%, -50%)rotate(-45deg);
}

.icon-arrow-right:before {
  transform: translate(-75%, -50%)rotate(135deg);
}

.icon-arrow-up:before {
  transform: translate(-50%, -25%)rotate(45deg);
}

.icon-back:after, .icon-forward:after {
  height: .1rem;
  width: .8em;
  background: currentColor;
}

.icon-downward:after, .icon-upward:after {
  height: .8em;
  width: .1rem;
  background: currentColor;
}

.icon-back:after {
  left: 55%;
}

.icon-back:before {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.icon-downward:after {
  top: 45%;
}

.icon-downward:before {
  transform: translate(-50%, -50%)rotate(-135deg);
}

.icon-forward:after {
  left: 45%;
}

.icon-forward:before {
  transform: translate(-50%, -50%)rotate(135deg);
}

.icon-upward:after {
  top: 55%;
}

.icon-upward:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-caret:before {
  height: 0;
  width: 0;
  border-top: .3em solid;
  border-left: .3em solid #0000;
  border-right: .3em solid #0000;
  transform: translate(-50%, -25%);
}

.icon-menu:before {
  height: .1rem;
  width: 100%;
  background: currentColor;
  box-shadow: 0 -.35em, 0 .35em;
}

.icon-apps:before {
  height: 3px;
  width: 3px;
  background: currentColor;
  box-shadow: -.35em -.35em, -.35em 0, -.35em .35em, 0 -.35em, 0 .35em, .35em -.35em, .35em 0, .35em .35em;
}

.icon-resize-horiz:before, .icon-resize-horiz:after, .icon-resize-vert:before, .icon-resize-vert:after {
  height: .45em;
  width: .45em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
}

.icon-resize-horiz:before, .icon-resize-vert:before {
  transform: translate(-50%, -90%)rotate(45deg);
}

.icon-resize-horiz:after, .icon-resize-vert:after {
  transform: translate(-50%, -10%)rotate(225deg);
}

.icon-resize-horiz:before {
  transform: translate(-90%, -50%)rotate(-45deg);
}

.icon-resize-horiz:after {
  transform: translate(-10%, -50%)rotate(135deg);
}

.icon-more-horiz:before, .icon-more-vert:before {
  height: 3px;
  width: 3px;
  background: currentColor;
  border-radius: 50%;
  box-shadow: -.4em 0, .4em 0;
}

.icon-more-vert:before {
  box-shadow: 0 -.4em, 0 .4em;
}

.icon-plus:before, .icon-minus:before, .icon-cross:before {
  height: .1rem;
  width: 100%;
  background: currentColor;
}

.icon-plus:after, .icon-cross:after {
  height: 100%;
  width: .1rem;
  background: currentColor;
}

.icon-cross:before {
  width: 100%;
}

.icon-cross:after {
  height: 100%;
}

.icon-cross:before, .icon-cross:after {
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-check:before {
  height: .5em;
  width: .9em;
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  transform: translate(-50%, -75%)rotate(-45deg);
}

.icon-stop {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-stop:before {
  height: .1rem;
  width: 1em;
  background: currentColor;
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-shutdown {
  border: .1rem solid;
  border-top-color: #0000;
  border-radius: 50%;
}

.icon-shutdown:before {
  content: "";
  height: .5em;
  width: .1rem;
  background: currentColor;
  top: .1em;
}

.icon-refresh:before {
  height: 1em;
  width: 1em;
  border: .1rem solid;
  border-right-color: #0000;
  border-radius: 50%;
}

.icon-refresh:after {
  height: 0;
  width: 0;
  border: .2em solid;
  border-color: #0000 currentColor currentColor #0000;
  top: 20%;
  left: 80%;
}

.icon-search:before {
  height: .75em;
  width: .75em;
  border: .1rem solid;
  border-radius: 50%;
  top: 5%;
  left: 5%;
  transform: translate(0)rotate(45deg);
}

.icon-search:after {
  height: .1rem;
  width: .4em;
  background: currentColor;
  top: 80%;
  left: 80%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-edit:before {
  height: .4em;
  width: .85em;
  border: .1rem solid;
  transform: translate(-40%, -60%)rotate(-45deg);
}

.icon-edit:after {
  height: 0;
  width: 0;
  border: .15em solid #0000;
  border-color: #0000 #0000 currentColor currentColor;
  top: 95%;
  left: 5%;
  transform: translate(0, -100%);
}

.icon-delete:before {
  height: .75em;
  width: .75em;
  border: .1rem solid;
  border-top: 0;
  border-bottom-right-radius: .1rem;
  border-bottom-left-radius: .1rem;
  top: 60%;
}

.icon-delete:after {
  height: .1rem;
  width: .5em;
  background: currentColor;
  top: .05rem;
  box-shadow: -.25em .2em, .25em .2em;
}

.icon-share {
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  border-radius: .1rem;
}

.icon-share:before {
  height: .4em;
  width: .4em;
  border: .1rem solid;
  border-top: 0;
  border-left: 0;
  top: .25em;
  left: 100%;
  transform: translate(-125%, -50%)rotate(-45deg);
}

.icon-share:after {
  height: .5em;
  width: .6em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: 75% 0;
}

.icon-flag:before {
  height: 1em;
  width: .1rem;
  background: currentColor;
  left: 15%;
}

.icon-flag:after {
  height: .65em;
  width: .8em;
  border: .1rem solid;
  border-left: 0;
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
  top: 35%;
  left: 60%;
}

.icon-bookmark:before {
  height: .9em;
  width: .8em;
  border: .1rem solid;
  border-bottom: 0;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.icon-bookmark:after {
  height: .5em;
  width: .5em;
  border-top: .1rem solid;
  border-bottom: 0;
  border-left: 0;
  border-right: .1rem solid;
  border-radius: .1rem;
  transform: translate(-50%, 35%)rotate(-45deg)skew(15deg, 15deg);
}

.icon-download, .icon-upload {
  border-bottom: .1rem solid;
}

.icon-download:before, .icon-upload:before {
  height: .5em;
  width: .5em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  transform: translate(-50%, -60%)rotate(-135deg);
}

.icon-download:after, .icon-upload:after {
  height: .6em;
  width: .1rem;
  background: currentColor;
  top: 40%;
}

.icon-upload:before {
  transform: translate(-50%, -60%)rotate(45deg);
}

.icon-upload:after {
  top: 50%;
}

.icon-copy:before {
  height: .8em;
  width: .8em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: .1rem;
  top: 35%;
  left: 40%;
}

.icon-copy:after {
  height: .8em;
  width: .8em;
  border: .1rem solid;
  border-radius: .1rem;
  top: 60%;
  left: 60%;
}

.icon-time {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-time:before {
  height: .4em;
  width: .1rem;
  background: currentColor;
  transform: translate(-50%, -75%);
}

.icon-time:after {
  height: .3em;
  transform-origin: 50% 90%;
  width: .1rem;
  background: currentColor;
  transform: translate(-50%, -75%)rotate(90deg);
}

.icon-mail:before {
  height: .8em;
  width: 1em;
  border: .1rem solid;
  border-radius: .1rem;
}

.icon-mail:after {
  height: .5em;
  width: .5em;
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  transform: translate(-50%, -90%)rotate(-45deg)skew(10deg, 10deg);
}

.icon-people:before {
  height: .45em;
  width: .45em;
  border: .1rem solid;
  border-radius: 50%;
  top: 25%;
}

.icon-people:after {
  height: .4em;
  width: .9em;
  border: .1rem solid;
  border-radius: 50% 50% 0 0;
  top: 75%;
}

.icon-message {
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: .1rem;
}

.icon-message:before {
  height: .8em;
  width: .7em;
  border: .1rem solid;
  border-top: 0;
  border-left: 0;
  border-bottom-right-radius: .1rem;
  top: 40%;
  left: 65%;
}

.icon-message:after {
  height: .3em;
  width: .1rem;
  background: currentColor;
  border-radius: .1rem;
  top: 100%;
  left: 10%;
  transform: translate(0, -90%)rotate(45deg);
}

.icon-photo {
  border: .1rem solid;
  border-radius: .1rem;
}

.icon-photo:before {
  height: .25em;
  width: .25em;
  border: .1rem solid;
  border-radius: 50%;
  top: 35%;
  left: 35%;
}

.icon-photo:after {
  height: .5em;
  width: .5em;
  border-top: .1rem solid;
  border-bottom: 0;
  border-left: 0;
  border-right: .1rem solid;
  left: 60%;
  transform: translate(-50%, 25%)rotate(-45deg);
}

.icon-link:before, .icon-link:after {
  height: .5em;
  width: .75em;
  border: .1rem solid;
  border-right: 0;
  border-radius: 5em 0 0 5em;
}

.icon-link:before {
  transform: translate(-70%, -45%)rotate(-45deg);
}

.icon-link:after {
  transform: translate(-30%, -55%)rotate(135deg);
}

.icon-location:before {
  height: .8em;
  width: .8em;
  border: .1rem solid;
  border-radius: 50% 50% 50% 0;
  transform: translate(-50%, -60%)rotate(-45deg);
}

.icon-location:after {
  height: .2em;
  width: .2em;
  border: .1rem solid;
  border-radius: 50%;
  transform: translate(-50%, -80%);
}

.icon-emoji {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-emoji:before {
  height: .15em;
  width: .15em;
  border-radius: 50%;
  box-shadow: -.17em -.1em, .17em -.1em;
}

.icon-emoji:after {
  height: .5em;
  width: .5em;
  border: .1rem solid #0000;
  border-color: currentColor #0000 #0000 currentColor;
  border-radius: 50%;
  transform: translate(-50%, -40%)rotate(-135deg);
}

.form-autocomplete {
  position: relative;
}

.form-autocomplete .form-autocomplete-input {
  height: auto;
  min-height: 1.6rem;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: .1rem;
  display: flex;
}

.form-autocomplete .form-autocomplete-input.is-focused {
  border-color: #0d84b3;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.form-autocomplete .form-autocomplete-input .form-input {
  box-shadow: none;
  height: 1.2rem;
  width: auto;
  border-color: #0000;
  flex: 1 0 auto;
  margin: .1rem;
  line-height: .8rem;
  display: inline-block;
}

.form-autocomplete .menu {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.form-autocomplete.autocomplete-oneline .form-autocomplete-input {
  flex-wrap: nowrap;
  overflow-x: auto;
}

.form-autocomplete.autocomplete-oneline .chip {
  flex: 1 0 auto;
}

.calendar {
  min-width: 280px;
  border: .05rem solid #b9b9b9;
  border-radius: .1rem;
  display: block;
}

.calendar .calendar-nav {
  background: #f1f1f1;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
  align-items: center;
  padding: .4rem;
  font-size: .9rem;
  display: flex;
}

.calendar .calendar-header, .calendar .calendar-body {
  flex-wrap: wrap;
  justify-content: center;
  padding: .4rem 0;
  display: flex;
}

.calendar .calendar-header .calendar-date, .calendar .calendar-body .calendar-date {
  max-width: 14.28%;
  flex: 0 0 14.28%;
}

.calendar .calendar-header {
  color: #868686;
  text-align: center;
  background: #f1f1f1;
  border-bottom: .05rem solid #b9b9b9;
  font-size: .7rem;
}

.calendar .calendar-body {
  color: #464646;
}

.calendar .calendar-date {
  border: 0;
  padding: .2rem;
}

.calendar .calendar-date .date-item {
  -webkit-appearance: none;
  appearance: none;
  color: #464646;
  cursor: pointer;
  height: 1.4rem;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: 1.4rem;
  background: none;
  border: .05rem solid #0000;
  border-radius: 50%;
  outline: none;
  padding: .1rem;
  font-size: .7rem;
  line-height: 1rem;
  text-decoration: none;
  transition: background .2s, border .2s, box-shadow .2s, color .2s;
  position: relative;
}

.calendar .calendar-date .date-item.date-today {
  color: #0d84b3;
  border-color: #7bd3f5;
}

.calendar .calendar-date .date-item:focus {
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.calendar .calendar-date .date-item:focus, .calendar .calendar-date .date-item:hover {
  color: #0d84b3;
  background: #97dcf7;
  border-color: #7bd3f5;
  text-decoration: none;
}

.calendar .calendar-date .date-item:active, .calendar .calendar-date .date-item.active {
  color: #fff;
  background: #0c79a5;
  border-color: #0a688d;
}

.calendar .calendar-date .date-item.badge:after {
  position: absolute;
  top: 3px;
  right: 3px;
  transform: translate(50%, -50%);
}

.calendar .calendar-date .date-item:disabled, .calendar .calendar-date .date-item.disabled, .calendar .calendar-date .calendar-event:disabled, .calendar .calendar-date .calendar-event.disabled {
  cursor: default;
  opacity: .25;
  pointer-events: none;
}

.calendar .calendar-date.prev-month .date-item, .calendar .calendar-date.prev-month .calendar-event, .calendar .calendar-date.next-month .date-item, .calendar .calendar-date.next-month .calendar-event {
  opacity: .25;
}

.calendar .calendar-range {
  position: relative;
}

.calendar .calendar-range:before {
  content: "";
  height: 1.4rem;
  background: #89d7f6;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.calendar .calendar-range.range-start:before {
  left: 50%;
}

.calendar .calendar-range.range-end:before {
  right: 50%;
}

.calendar .calendar-range.range-start .date-item, .calendar .calendar-range.range-end .date-item {
  color: #fff;
  background: #0c79a5;
  border-color: #0a688d;
}

.calendar .calendar-range .date-item {
  color: #0d84b3;
}

.calendar.calendar-lg .calendar-body {
  padding: 0;
}

.calendar.calendar-lg .calendar-body .calendar-date {
  height: 5.5rem;
  border-bottom: .05rem solid #b9b9b9;
  border-right: .05rem solid #b9b9b9;
  flex-direction: column;
  padding: 0;
  display: flex;
}

.calendar.calendar-lg .calendar-body .calendar-date:nth-child(7n) {
  border-right: 0;
}

.calendar.calendar-lg .calendar-body .calendar-date:nth-last-child(-n+7) {
  border-bottom: 0;
}

.calendar.calendar-lg .date-item {
  height: 1.4rem;
  align-self: flex-end;
  margin-top: .2rem;
  margin-right: .2rem;
}

.calendar.calendar-lg .calendar-range:before {
  top: 19px;
}

.calendar.calendar-lg .calendar-range.range-start:before {
  width: 19px;
  left: auto;
}

.calendar.calendar-lg .calendar-range.range-end:before {
  right: 19px;
}

.calendar.calendar-lg .calendar-events {
  flex-grow: 1;
  padding: .2rem;
  line-height: 1;
  overflow-y: auto;
}

.calendar.calendar-lg .calendar-event {
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: .1rem;
  margin: .1rem auto;
  padding: 3px 4px;
  font-size: .7rem;
  display: block;
  overflow: hidden;
}

.carousel .carousel-locator:nth-of-type(8):checked ~ .carousel-container .carousel-item:nth-of-type(8), .carousel .carousel-locator:nth-of-type(7):checked ~ .carousel-container .carousel-item:nth-of-type(7), .carousel .carousel-locator:nth-of-type(6):checked ~ .carousel-container .carousel-item:nth-of-type(6), .carousel .carousel-locator:nth-of-type(5):checked ~ .carousel-container .carousel-item:nth-of-type(5), .carousel .carousel-locator:nth-of-type(4):checked ~ .carousel-container .carousel-item:nth-of-type(4), .carousel .carousel-locator:nth-of-type(3):checked ~ .carousel-container .carousel-item:nth-of-type(3), .carousel .carousel-locator:nth-of-type(2):checked ~ .carousel-container .carousel-item:nth-of-type(2), .carousel .carousel-locator:first-of-type:checked ~ .carousel-container .carousel-item:first-of-type {
  opacity: 1;
  z-index: 100;
  animation: .75s ease-in-out carousel-slidein;
}

.carousel .carousel-locator:nth-of-type(8):checked ~ .carousel-nav .nav-item:nth-of-type(8), .carousel .carousel-locator:nth-of-type(7):checked ~ .carousel-nav .nav-item:nth-of-type(7), .carousel .carousel-locator:nth-of-type(6):checked ~ .carousel-nav .nav-item:nth-of-type(6), .carousel .carousel-locator:nth-of-type(5):checked ~ .carousel-nav .nav-item:nth-of-type(5), .carousel .carousel-locator:nth-of-type(4):checked ~ .carousel-nav .nav-item:nth-of-type(4), .carousel .carousel-locator:nth-of-type(3):checked ~ .carousel-nav .nav-item:nth-of-type(3), .carousel .carousel-locator:nth-of-type(2):checked ~ .carousel-nav .nav-item:nth-of-type(2), .carousel .carousel-locator:first-of-type:checked ~ .carousel-nav .nav-item:first-of-type {
  color: #b9b9b9;
}

.carousel {
  width: 100%;
  -webkit-overflow-scrolling: touch;
  z-index: 1;
  background: #f1f1f1;
  display: block;
  position: relative;
  overflow: hidden;
}

.carousel .carousel-container {
  height: 100%;
  position: relative;
  left: 0;
}

.carousel .carousel-container:before {
  content: "";
  padding-bottom: 56.25%;
  display: block;
}

.carousel .carousel-container .carousel-item {
  height: 100%;
  opacity: 0;
  width: 100%;
  margin: 0;
  animation: 1s ease-in-out carousel-slideout;
  position: absolute;
  top: 0;
  left: 0;
}

.carousel .carousel-container .carousel-item:hover .item-prev, .carousel .carousel-container .carousel-item:hover .item-next {
  opacity: 1;
}

.carousel .carousel-container .item-prev, .carousel .carousel-container .item-next {
  color: #b9b9b9;
  opacity: 0;
  z-index: 100;
  background: #b9b9b940;
  border-color: #b9b9b980;
  transition: all .4s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel .carousel-container .item-prev {
  left: 1rem;
}

.carousel .carousel-container .item-next {
  right: 1rem;
}

.carousel .carousel-nav {
  width: 10rem;
  z-index: 100;
  justify-content: center;
  display: flex;
  position: absolute;
  bottom: .4rem;
  left: 50%;
  transform: translateX(-50%);
}

.carousel .carousel-nav .nav-item {
  color: #b9b9b980;
  height: 1.6rem;
  max-width: 2.5rem;
  flex: 1 0 auto;
  margin: .2rem;
  display: block;
  position: relative;
}

.carousel .carousel-nav .nav-item:before {
  content: "";
  height: .1rem;
  width: 100%;
  background: currentColor;
  display: block;
  position: absolute;
  top: .5rem;
}

@keyframes carousel-slidein {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes carousel-slideout {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}

.comparison-slider {
  height: 50vh;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
}

.comparison-slider .comparison-before, .comparison-slider .comparison-after {
  height: 100%;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.comparison-slider .comparison-before img, .comparison-slider .comparison-after img {
  height: 100%;
  object-fit: cover;
  object-position: left center;
  width: 100%;
  position: absolute;
}

.comparison-slider .comparison-before {
  width: 100%;
  z-index: 1;
}

.comparison-slider .comparison-before .comparison-label {
  right: .8rem;
}

.comparison-slider .comparison-after {
  max-width: 100%;
  min-width: 0;
  z-index: 2;
}

.comparison-slider .comparison-after:before {
  content: "";
  cursor: default;
  height: 100%;
  z-index: 1;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  right: .8rem;
}

.comparison-slider .comparison-after:after {
  color: #fff;
  content: "";
  height: 3px;
  pointer-events: none;
  width: 3px;
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: .4rem;
  transform: translate(50%, -50%);
  box-shadow: 0 -5px, 0 5px;
}

.comparison-slider .comparison-after .comparison-label {
  left: .8rem;
}

.comparison-slider .comparison-resizer {
  cursor: ew-resize;
  height: .8rem;
  max-width: 100%;
  min-width: .8rem;
  opacity: 0;
  resize: horizontal;
  width: 0;
  outline: none;
  animation: 1.5s ease-in-out first-run;
  position: relative;
  top: 50%;
  left: 0;
  transform: translateY(-50%)scaleY(30);
}

.comparison-slider .comparison-label {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background: #20202080;
  padding: .2rem .4rem;
  position: absolute;
  bottom: .8rem;
}

@keyframes first-run {
  0% {
    width: 0;
  }

  25% {
    width: 2.4rem;
  }

  50% {
    width: .8rem;
  }

  75% {
    width: 1.2rem;
  }

  100% {
    width: 0;
  }
}

.filter .filter-tag#tag-8:checked ~ .filter-nav .chip[for="tag-8"], .filter .filter-tag#tag-7:checked ~ .filter-nav .chip[for="tag-7"], .filter .filter-tag#tag-6:checked ~ .filter-nav .chip[for="tag-6"], .filter .filter-tag#tag-5:checked ~ .filter-nav .chip[for="tag-5"], .filter .filter-tag#tag-4:checked ~ .filter-nav .chip[for="tag-4"], .filter .filter-tag#tag-3:checked ~ .filter-nav .chip[for="tag-3"], .filter .filter-tag#tag-2:checked ~ .filter-nav .chip[for="tag-2"], .filter .filter-tag#tag-1:checked ~ .filter-nav .chip[for="tag-1"], .filter .filter-tag#tag-0:checked ~ .filter-nav .chip[for="tag-0"] {
  color: #fff;
  background: #0d84b3;
}

.filter .filter-tag#tag-8:checked ~ .filter-body .filter-item:not([data-tag~="tag-8"]), .filter .filter-tag#tag-7:checked ~ .filter-body .filter-item:not([data-tag~="tag-7"]), .filter .filter-tag#tag-6:checked ~ .filter-body .filter-item:not([data-tag~="tag-6"]), .filter .filter-tag#tag-5:checked ~ .filter-body .filter-item:not([data-tag~="tag-5"]), .filter .filter-tag#tag-4:checked ~ .filter-body .filter-item:not([data-tag~="tag-4"]), .filter .filter-tag#tag-3:checked ~ .filter-body .filter-item:not([data-tag~="tag-3"]), .filter .filter-tag#tag-2:checked ~ .filter-body .filter-item:not([data-tag~="tag-2"]), .filter .filter-tag#tag-1:checked ~ .filter-body .filter-item:not([data-tag~="tag-1"]) {
  display: none;
}

.filter .filter-nav {
  margin: .4rem 0;
}

.filter .filter-body {
  flex-wrap: wrap;
  display: flex;
}

.meter {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: .8rem;
  background: #f1f1f1;
  border: 0;
  border-radius: .1rem;
  display: block;
}

.meter::-webkit-meter-inner-element {
  display: block;
}

.meter::-webkit-meter-bar, .meter::-webkit-meter-optimum-value, .meter::-webkit-meter-suboptimum-value, .meter::-webkit-meter-even-less-good-value {
  border-radius: .1rem;
}

.meter::-webkit-meter-bar {
  background: #f1f1f1;
}

.meter::-webkit-meter-optimum-value {
  background: #32b643;
}

.meter::-webkit-meter-suboptimum-value {
  background: #ffb700;
}

.meter::-webkit-meter-even-less-good-value {
  background: #e85600;
}

.meter::-moz-meter-bar, .meter:-moz-meter-optimum, .meter:-moz-meter-sub-optimum, .meter:-moz-meter-sub-sub-optimum {
  border-radius: .1rem;
}

.meter:-moz-meter-optimum::-moz-meter-bar {
  background: #32b643;
}

.meter:-moz-meter-sub-optimum::-moz-meter-bar {
  background: #ffb700;
}

.meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
  background: #e85600;
}

.off-canvas {
  height: 100%;
  width: 100%;
  flex-flow: row;
  display: flex;
  position: relative;
}

.off-canvas .off-canvas-toggle {
  z-index: 1;
  transition: none;
  display: block;
  position: absolute;
  top: .4rem;
  left: .4rem;
}

.off-canvas .off-canvas-sidebar {
  min-width: 10rem;
  z-index: 200;
  background: #f1f1f1;
  transition: transform .25s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  transform: translateX(-100%);
}

.off-canvas .off-canvas-content {
  height: 100%;
  flex: auto;
  padding: .4rem .4rem .4rem 4rem;
}

.off-canvas .off-canvas-overlay {
  height: 100%;
  width: 100%;
  background: #2020201a;
  border-color: #0000;
  border-radius: 0;
  display: none;
  position: fixed;
  inset: 0;
}

.off-canvas .off-canvas-sidebar:target, .off-canvas .off-canvas-sidebar.active {
  transform: translateX(0);
}

.off-canvas .off-canvas-sidebar:target ~ .off-canvas-overlay, .off-canvas .off-canvas-sidebar.active ~ .off-canvas-overlay {
  z-index: 100;
  display: block;
}

@media (min-width: 960px) {
  .off-canvas.off-canvas-sidebar-show .off-canvas-toggle {
    display: none;
  }

  .off-canvas.off-canvas-sidebar-show .off-canvas-sidebar {
    flex: none;
    position: relative;
    transform: none;
  }

  .off-canvas.off-canvas-sidebar-show .off-canvas-overlay {
    display: none !important;
  }
}

.parallax {
  height: auto;
  width: auto;
  display: block;
  position: relative;
}

.parallax .parallax-content {
  height: auto;
  transform-style: preserve-3d;
  width: 100%;
  transition: all .4s;
  transform: perspective(1000px);
  box-shadow: 0 1rem 2.1rem #2020204d;
}

.parallax .parallax-content:before {
  content: "";
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.parallax .parallax-front {
  color: #fff;
  height: 100%;
  text-align: center;
  text-shadow: 0 0 20px #202020bf;
  width: 100%;
  z-index: 1;
  justify-content: center;
  align-items: center;
  transition: transform .4s;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(50px)scale(.95);
}

.parallax .parallax-top-left {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: none;
  position: absolute;
  top: 0;
  left: 0;
}

.parallax .parallax-top-left:focus ~ .parallax-content, .parallax .parallax-top-left:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(3deg)rotateY(-3deg);
}

.parallax .parallax-top-left:focus ~ .parallax-content:before, .parallax .parallax-top-left:hover ~ .parallax-content:before {
  background: linear-gradient(135deg, #ffffff59 0%, #0000 50%);
}

.parallax .parallax-top-left:focus ~ .parallax-content .parallax-front, .parallax .parallax-top-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(4.5px, 4.5px, 50px)scale(.95);
}

.parallax .parallax-top-right {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: none;
  position: absolute;
  top: 0;
  right: 0;
}

.parallax .parallax-top-right:focus ~ .parallax-content, .parallax .parallax-top-right:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(3deg)rotateY(3deg);
}

.parallax .parallax-top-right:focus ~ .parallax-content:before, .parallax .parallax-top-right:hover ~ .parallax-content:before {
  background: linear-gradient(-135deg, #ffffff59 0%, #0000 50%);
}

.parallax .parallax-top-right:focus ~ .parallax-content .parallax-front, .parallax .parallax-top-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-4.5px, 4.5px, 50px)scale(.95);
}

.parallax .parallax-bottom-left {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.parallax .parallax-bottom-left:focus ~ .parallax-content, .parallax .parallax-bottom-left:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(-3deg)rotateY(-3deg);
}

.parallax .parallax-bottom-left:focus ~ .parallax-content:before, .parallax .parallax-bottom-left:hover ~ .parallax-content:before {
  background: linear-gradient(45deg, #ffffff59 0%, #0000 50%);
}

.parallax .parallax-bottom-left:focus ~ .parallax-content .parallax-front, .parallax .parallax-bottom-left:hover ~ .parallax-content .parallax-front {
  transform: translate3d(4.5px, -4.5px, 50px)scale(.95);
}

.parallax .parallax-bottom-right {
  height: 50%;
  width: 50%;
  z-index: 100;
  outline: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.parallax .parallax-bottom-right:focus ~ .parallax-content, .parallax .parallax-bottom-right:hover ~ .parallax-content {
  transform: perspective(1000px)rotateX(-3deg)rotateY(3deg);
}

.parallax .parallax-bottom-right:focus ~ .parallax-content:before, .parallax .parallax-bottom-right:hover ~ .parallax-content:before {
  background: linear-gradient(-45deg, #ffffff59 0%, #0000 50%);
}

.parallax .parallax-bottom-right:focus ~ .parallax-content .parallax-front, .parallax .parallax-bottom-right:hover ~ .parallax-content .parallax-front {
  transform: translate3d(-4.5px, -4.5px, 50px)scale(.95);
}

.progress {
  -webkit-appearance: none;
  appearance: none;
  color: #0d84b3;
  height: .2rem;
  width: 100%;
  background: #e9e9e9;
  border: 0;
  border-radius: .1rem;
  position: relative;
}

.progress::-webkit-progress-bar {
  background: none;
  border-radius: .1rem;
}

.progress::-webkit-progress-value {
  background: #0d84b3;
  border-radius: .1rem;
}

.progress::-moz-progress-bar {
  background: #0d84b3;
  border-radius: .1rem;
}

.progress:indeterminate {
  background: #e9e9e9 linear-gradient(to right, #0d84b3 30%, #e9e9e9 30%) 0 0 / 150% 150% no-repeat;
  animation: 1.5s linear infinite progress-indeterminate;
}

.progress:indeterminate::-moz-progress-bar {
  background: none;
}

@keyframes progress-indeterminate {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 1.2rem;
  background: none;
  display: block;
}

.slider:focus {
  outline: none;
  box-shadow: 0 0 0 .1rem #0d84b333;
}

.slider.tooltip:not([data-tooltip]):after {
  content: attr(value);
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: .6rem;
  width: .6rem;
  background: #0d84b3;
  border: 0;
  border-radius: 50%;
  margin-top: -.25rem;
  transition: transform .2s;
}

.slider::-moz-range-thumb {
  height: .6rem;
  width: .6rem;
  background: #0d84b3;
  border: 0;
  border-radius: 50%;
  transition: transform .2s;
}

.slider::-ms-thumb {
  height: .6rem;
  width: .6rem;
  background: #0d84b3;
  border: 0;
  border-radius: 50%;
  transition: transform .2s;
}

.slider:active::-webkit-slider-thumb {
  transform: scale(1.25);
}

.slider:active::-moz-range-thumb {
  transform: scale(1.25);
}

.slider:active::-ms-thumb {
  transform: scale(1.25);
}

.slider:disabled::-webkit-slider-thumb, .slider.disabled::-webkit-slider-thumb {
  background: #b9b9b9;
  transform: scale(1);
}

.slider:disabled::-moz-range-thumb, .slider.disabled::-moz-range-thumb {
  background: #b9b9b9;
  transform: scale(1);
}

.slider:disabled::-ms-thumb, .slider.disabled::-ms-thumb {
  background: #b9b9b9;
  transform: scale(1);
}

.slider::-webkit-slider-runnable-track {
  height: .1rem;
  width: 100%;
  background: #e9e9e9;
  border-radius: .1rem;
}

.slider::-moz-range-track {
  height: .1rem;
  width: 100%;
  background: #e9e9e9;
  border-radius: .1rem;
}

.slider::-ms-track {
  height: .1rem;
  width: 100%;
  background: #e9e9e9;
  border-radius: .1rem;
}

.slider::-ms-fill-lower {
  background: #0d84b3;
}

.timeline .timeline-item {
  margin-bottom: 1.2rem;
  display: flex;
  position: relative;
}

.timeline .timeline-item:before {
  content: "";
  height: 100%;
  width: 2px;
  background: #b9b9b9;
  position: absolute;
  top: 1.2rem;
  left: 11px;
}

.timeline .timeline-item .timeline-left {
  flex: none;
}

.timeline .timeline-item .timeline-content {
  flex: auto;
  padding: 2px 0 2px .8rem;
}

.timeline .timeline-item .timeline-icon {
  color: #fff;
  height: 1.2rem;
  text-align: center;
  width: 1.2rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.timeline .timeline-item .timeline-icon:before {
  content: "";
  height: .4rem;
  width: .4rem;
  border: .1rem solid #0d84b3;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: .4rem;
  left: .4rem;
}

.timeline .timeline-item .timeline-icon.icon-lg {
  background: #0d84b3;
  line-height: 1rem;
}

.timeline .timeline-item .timeline-icon.icon-lg:before {
  content: none;
}

.viewer-360 {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.viewer-360 .viewer-slider[max="36"][value="1"] + .viewer-image {
  background-position-y: 0%;
}

.viewer-360 .viewer-slider[max="36"][value="2"] + .viewer-image {
  background-position-y: 2.85714%;
}

.viewer-360 .viewer-slider[max="36"][value="3"] + .viewer-image {
  background-position-y: 5.71429%;
}

.viewer-360 .viewer-slider[max="36"][value="4"] + .viewer-image {
  background-position-y: 8.57143%;
}

.viewer-360 .viewer-slider[max="36"][value="5"] + .viewer-image {
  background-position-y: 11.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="6"] + .viewer-image {
  background-position-y: 14.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="7"] + .viewer-image {
  background-position-y: 17.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="8"] + .viewer-image {
  background-position-y: 20%;
}

.viewer-360 .viewer-slider[max="36"][value="9"] + .viewer-image {
  background-position-y: 22.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="10"] + .viewer-image {
  background-position-y: 25.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="11"] + .viewer-image {
  background-position-y: 28.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="12"] + .viewer-image {
  background-position-y: 31.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="13"] + .viewer-image {
  background-position-y: 34.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="14"] + .viewer-image {
  background-position-y: 37.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="15"] + .viewer-image {
  background-position-y: 40%;
}

.viewer-360 .viewer-slider[max="36"][value="16"] + .viewer-image {
  background-position-y: 42.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="17"] + .viewer-image {
  background-position-y: 45.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="18"] + .viewer-image {
  background-position-y: 48.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="19"] + .viewer-image {
  background-position-y: 51.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="20"] + .viewer-image {
  background-position-y: 54.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="21"] + .viewer-image {
  background-position-y: 57.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="22"] + .viewer-image {
  background-position-y: 60%;
}

.viewer-360 .viewer-slider[max="36"][value="23"] + .viewer-image {
  background-position-y: 62.8571%;
}

.viewer-360 .viewer-slider[max="36"][value="24"] + .viewer-image {
  background-position-y: 65.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="25"] + .viewer-image {
  background-position-y: 68.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="26"] + .viewer-image {
  background-position-y: 71.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="27"] + .viewer-image {
  background-position-y: 74.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="28"] + .viewer-image {
  background-position-y: 77.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="29"] + .viewer-image {
  background-position-y: 80%;
}

.viewer-360 .viewer-slider[max="36"][value="30"] + .viewer-image {
  background-position-y: 82.8572%;
}

.viewer-360 .viewer-slider[max="36"][value="31"] + .viewer-image {
  background-position-y: 85.7143%;
}

.viewer-360 .viewer-slider[max="36"][value="32"] + .viewer-image {
  background-position-y: 88.5714%;
}

.viewer-360 .viewer-slider[max="36"][value="33"] + .viewer-image {
  background-position-y: 91.4286%;
}

.viewer-360 .viewer-slider[max="36"][value="34"] + .viewer-image {
  background-position-y: 94.2857%;
}

.viewer-360 .viewer-slider[max="36"][value="35"] + .viewer-image {
  background-position-y: 97.1429%;
}

.viewer-360 .viewer-slider[max="36"][value="36"] + .viewer-image {
  background-position-y: 100%;
}

.viewer-360 .viewer-slider {
  cursor: ew-resize;
  width: 60%;
  order: 2;
  margin: 1rem;
}

.viewer-360 .viewer-image {
  max-width: 100%;
  background-position-y: 0;
  background-repeat: no-repeat;
  background-size: 100%;
  order: 1;
}

.btn:focus, .btn:hover {
  color: #fff;
  background: #0b729b;
}

/*# sourceMappingURL=main.css.map */
